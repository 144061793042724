import React, { ReactNode } from "react";
import { HTMLAttributes } from "react";

import cn from "classnames";

import * as styles from "./Container.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  rootClassName?: string;
}

export const Container: React.FC<Props> = ({
  children,
  className,
  rootClassName,
  ...other
}) => {
  return (
    <div className={cn(styles.root, rootClassName)} {...other}>
      <div className={cn(styles.container, className)}>{children}</div>
    </div>
  );
};
