import React, { useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { ParallaxProvider } from "react-scroll-parallax";
import { ToastContainer } from "react-toastify";
import { Footer } from "src/components/Footer";
import { Header, Props as HeaderProps } from "src/components/Header";
import { Props as MobileNavProps } from "src/components/MobileNav";
import "react-toastify/dist/ReactToastify.css";

import "src/styles/main.scss";
import * as styles from "./PageWrapper.module.scss";

interface Props {
  headerProps?: HeaderProps;
  topAutoScroll?: boolean;
  title?: string;
  mobileNavProps?: MobileNavProps;
}

export const PageWrapper: React.FC<Props> = ({
  children,
  headerProps,
  topAutoScroll,
  title,
  mobileNavProps,
}) => {
  useEffect(() => {
    if (topAutoScroll) {
      window.scrollTo(0, 0);
    }
  }, [topAutoScroll]);

  return (
    <ParallaxProvider>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <div className={styles.root}>
        <Header {...headerProps} mobileNavProps={mobileNavProps} />

        <main className={styles.main}>{children}</main>

        <Footer />
      </div>
      <ToastContainer />
    </ParallaxProvider>
  );
};
