import React, { AnchorHTMLAttributes } from "react";

import cn from "classnames";

import arrow from "./arrow.svg";
import * as styles from "./Link.module.scss";

export interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  size?: "s" | "m" | "l";
  color?: "black" | "white" | "green" | "blue";
  hoverFill?: "black" | "purple" | "none" | "white" | "green" | "blue" | "pink";
  rounded?: boolean;
  showArrow?: boolean;
  arrowPosition?: "left" | "right";
  disableArrowHide?: boolean;
  active?: boolean;
  noWrap?: boolean;
}

export const Link: React.FC<Props> = ({
  className,
  size,
  color,
  hoverFill,
  rounded = true,
  showArrow,
  arrowPosition = "right",
  disableArrowHide,
  active,
  href,
  noWrap,
  children,
  ...other
}) => {
  return (
    <a
      href={active ? href : href}
      {...other}
      className={cn(
        className,
        styles.root,
        styles[size],
        styles[color],
        styles[hoverFill + "HoverFill"],
        showArrow && styles.none,
        !rounded && styles.notRounded,
        showArrow && styles.showArrow,
        styles[arrowPosition],
        disableArrowHide && styles.disableArrowHide,
        active && styles.active,
        active && styles.none,
        noWrap && styles.noWrap
      )}
    >
      {showArrow && (
        <div className={styles.arrow}>
          <img src={arrow} alt="arrow" />
        </div>
      )}

      <span className={styles.inner}>{children}</span>
    </a>
  );
};

Link.defaultProps = {
  size: "s",
  color: "white",
  hoverFill: "purple",
};
