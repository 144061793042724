import React from "react";

import cn from "classnames";
import { PathEnum } from "src/enums/PathEnum";
import { createLink } from "src/utils/createLink";

import * as styles from "./Logo.module.scss";
import logo from "./Logo.svg";

interface Props {
  size?: "s" | "l";
  color?: "white" | "black" | "purple";
  className?: string;
  link?: boolean;
}

export const Logo: React.FC<Props> = ({
  size = "s",
  color,
  className,
  link,
}) => {
  const handleClick = () => {
    if (link) {
      window.location.href = createLink(PathEnum.Home);
    }
  };

  // Не работает через параметр по умолчанию
  const style = [styles[size], styles[color]];

  const image = (
    <img
      src={logo}
      alt="abdt-logo"
      className={cn(style, className, link && styles.link)}
      onClick={handleClick}
    />
  );

  return image;
};
