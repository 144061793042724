// extracted by mini-css-extract-plugin
export var root = "Link-module--root--3LSUx";
export var inner = "Link-module--inner--7IeSn";
export var noWrap = "Link-module--noWrap--gOswe";
export var white = "Link-module--white--3LSXH";
export var black = "Link-module--black--TpcZD";
export var blue = "Link-module--blue--24v-G";
export var green = "Link-module--green--1JsEn";
export var active = "Link-module--active--2Ee3O";
export var s = "Link-module--s--3jIjj";
export var m = "Link-module--m--2wTRl";
export var notRounded = "Link-module--notRounded--3ejym";
export var purpleHoverFill = "Link-module--purpleHoverFill--t1xeb";
export var none = "Link-module--none--2R-kC";
export var blackHoverFill = "Link-module--blackHoverFill--3fUaN";
export var whiteHoverFill = "Link-module--whiteHoverFill--2HPt7";
export var pinkHoverFill = "Link-module--pinkHoverFill--1FItd";
export var greenHoverFill = "Link-module--greenHoverFill--qxUcS";
export var showArrow = "Link-module--showArrow--37WSf";
export var arrow = "Link-module--arrow--1iDly";
export var left = "Link-module--left--1PeAG";
export var right = "Link-module--right--IO47j";
export var disableArrowHide = "Link-module--disableArrowHide--1gHTW";