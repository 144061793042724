import { PathEnum } from "src/enums/PathEnum";

export const createLink = (
  path: PathEnum = PathEnum.Current,
  params?: { [key: string]: string | number | boolean }
) => {
  const query = [];

  for (const name in params) {
    query.push(
      encodeURIComponent(name) + "=" + encodeURIComponent(params[name])
    );
  }

  return path + (query.length ? "?" : "") + query.join("&");
};
