import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  query {
    allStrapiLinks {
      edges {
        node {
          text
          link
        }
      }
    }
  }
`;

export const useAppLinks = () => {
  const data = useStaticQuery(query);

  return data.allStrapiLinks.edges.map(({ node }) => node);
};
