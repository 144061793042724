import React from "react";

import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";

import * as styles from "./Footer.module.scss";

export const Footer: React.FC = ({ children }) => {
  return (
    <Container className={styles.root}>
      {children ?? (
        <>
          <div>
            <Typography>
              <a href="mailto:hr@akbarsdigital.ru">hr@akbarsdigital.ru</a>
            </Typography>
          </div>
          <div>
            <Typography>
              <a href="/privacy_policy.pdf" target="_blank">
                Политика обработки <br /> персональных данных
              </a>
            </Typography>
          </div>
        </>
      )}
      <Typography className={styles.back}>Ak Bars Digital</Typography>
    </Container>
  );
};
