// extracted by mini-css-extract-plugin
export var bar = "MobileNav-module--bar--3XE9w";
export var scrolled = "MobileNav-module--scrolled--2K-bf";
export var toggle = "MobileNav-module--toggle--1UIM9";
export var openedToggle = "MobileNav-module--openedToggle--28XQZ";
export var navigation = "MobileNav-module--navigation--4NB9i";
export var opened = "MobileNav-module--opened--2BEqK";
export var navButton = "MobileNav-module--navButton--2FxS9";
export var link = "MobileNav-module--link--J8eRp";
export var links = "MobileNav-module--links--2uQ3B";
export var contacts = "MobileNav-module--contacts--3BGCt";
export var email = "MobileNav-module--email--3ypkq";