// extracted by mini-css-extract-plugin
export var root = "Typography-module--root--3P1sV";
export var base = "Typography-module--base--3JZIV";
export var shouldBeAnimated = "Typography-module--shouldBeAnimated--1PD--";
export var animated = "Typography-module--animated--3av9n";
export var textAnimation = "Typography-module--textAnimation--3HwpO";
export var h3 = "Typography-module--h3--1z3ni";
export var h4 = "Typography-module--h4--20NOS";
export var h5 = "Typography-module--h5--6yvp7";
export var p = "Typography-module--p--LjyRa";
export var caption = "Typography-module--caption--ZuVyV";
export var link = "Typography-module--link--1osex";
export var white = "Typography-module--white--3lpaE";