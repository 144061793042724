import React, { useEffect, useState } from "react";

import cn from "classnames";
import { Container } from "src/components/Container";
import { Logo } from "src/components/Logo";
import { Typography } from "src/components/Typography";
import { useAppLinks } from "src/hooks/useAppLinks";

import { Telegram } from "../Telegram/index";
import * as styles from "./MobileNav.module.scss";

export interface Props {
  scrolled?: boolean;
  /**
   * Смена цвета при прокрутке, по умолчания истинно
   */
  isCollorChangingOnScroll?: boolean;
}

export const MobileNav: React.FC<Props> = ({
  scrolled,
  isCollorChangingOnScroll = true,
}) => {
  const [opened, setOpened] = useState(false);

  const handleToggle = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    document.body.style.overflow = opened ? "hidden" : "auto";
  }, [opened]);

  const links = useAppLinks();

  return (
    <>
      <div
        className={cn(
          styles.bar,
          (!isCollorChangingOnScroll || (scrolled && !opened)) &&
            styles.scrolled
        )}
      ></div>

      <div
        className={cn(
          styles.toggle,
          opened && styles.openedToggle,
          (!isCollorChangingOnScroll || scrolled) && styles.scrolled
        )}
        onClick={handleToggle}
      >
        <div></div>

        <div></div>
      </div>

      <Container className={cn(styles.navigation, opened && styles.opened)}>
        <div>
          <Logo size="s" link />
        </div>

        <nav className={styles.links}>
          {links.map((item, index) => (
            <a className={styles.link} key={index} href={item.link}>
              {item.text}
            </a>
          ))}
        </nav>

        <div className={styles.contacts}>
          <Telegram size="xs" color="white" link /> <p />
          <Typography color="white">
            Не нашел подходящую вакансию? <p />
            Напиши на
            <br />
            <div className={styles.email}>
              <a href="mailto:hr@akbarsdigital.ru">hr@akbarsdigital.ru</a>
            </div>
            и мы свяжемся с тобой
          </Typography>
        </div>
      </Container>
    </>
  );
};
