export enum PathEnum {
  Current = "",
  Events = "/events",
  Home = "/",
  Vacancies = "/vacancies",
  Vacancy = "/vacancy",
  News = "/news",
  NotFound = "/404",
  Projects = "/projects",
  Telegram = "https://t.me/job_akbarsdigital",
  MakeItLoud = "/makeitloud",
}
