import React from "react";

import cn from "classnames";
import { Container } from "src/components/Container";
import { Link } from "src/components/Link";
import { Logo } from "src/components/Logo";
import { MobileNav, Props as mobileNavProps } from "src/components/MobileNav";
import { useWindowScroll } from "src/hooks";
import { useAppLinks } from "src/hooks/useAppLinks";

import { Telegram } from "../Telegram/index";
import * as styles from "./Header.module.scss";

export interface Props {
  colorChangeable?: boolean;
  forceShow?: boolean;
  mobileNavProps?: mobileNavProps;
}

export const Header: React.FC<Props> = ({
  colorChangeable = false,
  forceShow,
  mobileNavProps,
}) => {
  const links = useAppLinks();
  const scrolled = forceShow || useWindowScroll();

  return (
    <div className={styles.root}>
      <nav
        className={cn(
          styles.navbar,
          (scrolled || !colorChangeable) && styles.whiteBackground
        )}
      >
        <Container className={styles.container}>
          {(scrolled || !colorChangeable) && (
            <Logo size="s" color="black" link />
          )}

          <div className={styles.wrap}>
            <Telegram
              size="xs"
              color={scrolled || !colorChangeable ? "black" : "white"}
              link
            />
            <div className={styles.links}>
              {links.map((item, index) => (
                <Link
                  color={scrolled || !colorChangeable ? "black" : "white"}
                  key={index}
                  href={item.link}
                >
                  {item.text}
                </Link>
              ))}
            </div>
          </div>
        </Container>
      </nav>

      <MobileNav scrolled={scrolled} {...mobileNavProps} />
    </div>
  );
};
