import { useEffect, useState } from "react";

export function useWindowScroll() {
  const [scrolled, setScrolled] = useState(false);

  const handleWindowScroll = () => {
    setScrolled(window?.pageYOffset > 100);
  };

  useEffect(() => {
    handleWindowScroll();

    window.addEventListener("scroll", handleWindowScroll);

    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  return scrolled;
}
