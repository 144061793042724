import React, { createRef, useEffect, useState } from "react";

import cn from "classnames";

import * as styles from "./Typography.module.scss";

export type TypographyVariant =
  | "h1"
  | "h3"
  | "h4"
  | "h5"
  | "p"
  | "caption"
  | "link";

export interface TypographyProps {
  className?: string;
  variant?: TypographyVariant;
  color?: "white" | "black";
  animate?: boolean;
  href?: string
}

export const Typography: React.FC<TypographyProps> = ({
  className,
  variant = "p",
  color = "black",
  animate,
  ...other
}) => {
  const [shown, setShown] = useState(false);
  const rootRef = createRef<HTMLDivElement>();

  const handleScroll = () => {
    if (rootRef.current) {
      if (
        rootRef.current.getBoundingClientRect().bottom <
        window.innerHeight * 0.9
      ) {
        setShown(true);
      }
    }
  };

  useEffect(() => {
    if (animate) {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [animate]);

  const style = cn(
    styles.base,
    styles[variant],
    styles[color],
    animate && styles.shouldBeAnimated,
    animate && shown && styles.animated,
    className
  );

  let component;

  switch (variant) {
    case "h3":
      component = <h3 className={style} {...other} />;
      break;
    case "h4":
      component = <h4 className={style} {...other} />;
      break;
    case "h5":
      component = <h5 className={style} {...other} />;
      break;
    case "p":
      component = <p className={style} {...other} />;
      break;
    case "caption":
      component = <p className={style} {...other} />;
      break;
    case "link":
      component = <a className={style} {...other} />;
      break;
    default:
      component = <p className={style} {...other} />;
  }

  if (animate) {
    return (
      <div className={cn(animate && styles.root)} ref={rootRef}>
        {component}
      </div>
    );
  }

  return component;
};
